import React from "react";
import styled from "styled-components";
import * as colors from "../../styles/colors";

const Wrapper = styled.div`
    height: 52px;
    cursor: pointer;
`;

export default function ArrowRight({ active, ...props }) {
    const color = active ? colors.DARK_GREEN : colors.DISABLED;
    return (
        <Wrapper {...props}>
            <svg
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="51"
                    height="51"
                    rx="3.5"
                    fill="white"
                    stroke={color}
                />
                <path
                    d="M24.6719 19.1172C24.2812 19.5078 24.3203 20.0938 24.6719 20.4844L29.3984 24.9375H18.1875C17.6406 24.9375 17.25 25.3672 17.25 25.875V27.125C17.25 27.6719 17.6406 28.0625 18.1875 28.0625H29.3984L24.6719 32.5547C24.3203 32.9453 24.3203 33.5312 24.6719 33.9219L25.5312 34.7812C25.9219 35.1328 26.5078 35.1328 26.8594 34.7812L34.4766 27.1641C34.8281 26.8125 34.8281 26.2266 34.4766 25.8359L26.8594 18.2578C26.5078 17.9062 25.9219 17.9062 25.5312 18.2578L24.6719 19.1172Z"
                    fill={color}
                />
            </svg>
        </Wrapper>
    );
}
