import React from "react";
import styled from "styled-components";
import * as colors from "../../styles/colors";
import Partners from "./Partners";

import breakpoints from "../design-system/Breakpoints";
import HeroRow from "../design-system/HeroRow";
import FixedWidth from "../design-system/FixedWidth";
import { fontFamilies, headerSizes } from "../../styles/fonts";

const Container = styled.div`
    display: flex;

    @media (max-width: ${breakpoints.small}) {
        flex-wrap: wrap;
        margin-top: 100px;
        margin: 100px 2em 0 2em;
    }
    @media (min-width: ${breakpoints.small} and max-width: ${breakpoints.large}) {
        margin: 0 140px 0 140px;
    }
`;

const H2 = styled.h2`
    font-family: ${fontFamilies.header};
    color: ${colors.NEW_TEAL};
    font-weight: 700;
    font-size: ${headerSizes.desktop};
    line-height: 52px;

    margin: 0 0 0 0;
    max-width: 80%;

    @media (max-width: ${breakpoints.small}) {
        margin-top: 80px;
    }
`;

const PartnersContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const Text = styled.p`
    font-family: Roboto, Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 140%;
    margin-right: auto;
    max-width: 80%;
    margin-top: 24px;
`;

const Row = styled(HeroRow)`
    flex: 1;
    align-items: center;

    @media (min-width: ${breakpoints.small}) {
        margin: 0 140px 140px 140px;
    }

    @media (max-width: ${breakpoints.small}) {
        flex-direction: column;
        margin-bottom: 80px;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    background-color: ${colors.LIGHTER_TEAL};
`;

export default function PartnersRow() {
    return (
        <Wrapper>
            <FixedWidth>
                <Container>
                    <Row>
                        <PartnersContainer>
                            <H2>Our trusted partners, more coming soon!</H2>
                            <Text>
                                TestBox currently enables you to test different <strong>Customer Support</strong> solutions.
                                Stay tuned as we expand quickly into
                                other software verticals.
                            </Text>
                        </PartnersContainer>
                        <Partners />
                    </Row>
                </Container>
            </FixedWidth>
        </Wrapper>
    );
}
