import * as React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { DARK } from "../styles/themes";

import Hero from "../component/home/Hero";
import PartnersRow from "../component/home/PartnersRow";
import Testimonials from "../component/home/Testimonials";
import Footer from "../component/Footer";
import FAQ from "../component/FAQ";
import CTABlock from "../component/CTABlock";
import { Helmet } from "react-helmet";
import Favicon from "../assets/favicon.png";
import Evaluation from "../component/home/Evaluation";

const NoMarginBody = createGlobalStyle`
    body {
    margin: 0;
    }
`;

const IndexPage = () => {
    return (
        <ThemeProvider theme={DARK}>
            <NoMarginBody />
            <Helmet>
                <title>TestBox</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link rel="shortcut icon" href={Favicon} />
            </Helmet>
            <Hero />
            <Evaluation />
            <PartnersRow />
            <Testimonials />
            <FAQ />
            <CTABlock />
            <Footer />
        </ThemeProvider>
    );
};

export default IndexPage;
