import styled from "styled-components";
import { fontFamilies } from "../../styles/fonts";
import { textColors } from "../../styles/colors";
import breakpoints from "../design-system/Breakpoints";
import OldBlock, { Header } from "../Block";

export const Img = styled.img`
    max-width: 100%;
    max-height: 200px;
`;

export const ImgWrapper = styled.div`
    flex: 1;
    margin-right: 2em;
    text-align: right;
`;

export const BWImg = styled.img`
    max-width: 100%;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    max-height: 200px;
`;

export const Wrapper = styled.div`
    display: grid;
    grid-gap: 80px;

    @media (min-width: ${breakpoints.small}) {
        grid-template-areas:
            "1 2"
            "3 4";
    }
    @media (max-width: ${breakpoints.small}) {
        grid-template-areas:
            "1"
            "2"
            "3"
            "4";
    }
`;

export const PointWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const PointText = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    line-height: 162%;
    font-family: ${fontFamilies.text};
    font-size: 1.125rem;
`;

export const PointHeader = styled.span`
    font-family: ${fontFamilies.header};
    font-size: 24px;
    color: ${textColors[0]};
    font-weight: 600;
    margin-bottom: 16px;
`;

export const PointSubtext = styled.span`
    font-family: ${fontFamilies.text};
    font-size: 1.125rem;
    color: ${textColors[1]};
`;

export const Block = styled(OldBlock)`
    ${Header} {
        padding-bottom: 40px;
    }
`;