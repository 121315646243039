import React from "react";
import styled from "styled-components";
import * as colors from "../../styles/colors";
import breakpoints from "../design-system/Breakpoints";

import Zendesk from "../../assets/zendesk";
import Hubspot from "../../assets/hubspot";
import Freshdesk from "../../assets/freshdesk";

const SPECIAL_BREAKPOINT = "499px";

const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;

    @media (min-width: ${breakpoints.small}) {
        flex-direction: row;
    }
    @media (max-width: ${SPECIAL_BREAKPOINT}) {
        flex-direction: column;
    }
    @media (max-width: ${breakpoints.small}) {
        padding-top: 40px;
        padding-bottom: 0;
        margin-bottom: 0;
    }
`;

const Partner = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

    @media (max-width: ${SPECIAL_BREAKPOINT}) {
        border-bottom: 1px solid ${colors.LIGHTER_TEAL};
        padding-bottom: 40px;

        // :not(:first-of-type) {
        //     margin-top: 40px;
        // }
    }
`;

const ComingSoon = styled.div`
    font-family: Roboto, Arial, sans-serif;
    margin-top: 40px;
    padding: 12px 24px;
    color: ${colors.GRAY};
`;

const Logo = styled.div`
    height: 37px;
`;

const Button = styled.a`
    background-color: ${({ theme }) => theme.tryItOut.background};
    color: ${({ theme }) => theme.tryItOut.color};

    font-family: Roboto, Arial, sans-serif;
    text-decoration: none;
    padding: 12px 24px;
    border-radius: 4px;
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
`;

export default function Partners() {
    return (
        <Container>
            <Partner>
                <Logo>
                    <Zendesk />
                </Logo>
                <Button href="https://onboarding.testbox.com/">
                    Try it out!
                </Button>
            </Partner>
            <Partner>
                <Logo>
                    <Hubspot />
                </Logo>
                <ComingSoon>Coming soon!</ComingSoon>
            </Partner>
            <Partner>
                <Logo>
                    <Freshdesk />
                </Logo>
                <ComingSoon>Coming soon!</ComingSoon>
            </Partner>
        </Container>
    );
}
