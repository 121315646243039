import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import Testimonial from "./Testimonial";
import Block, { Header } from "../Block";

import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import ArrowLeft from "../design-system/ArrowLeft";
import ArrowRight from "../design-system/ArrowRight";
import breakpoints from "../design-system/Breakpoints";

const testimonials = [
    {
        header: "Why doesn't this exist already?",
        text:
            "Testbox could literally eliminate 70% of the buying process for me right away. Your prototype is getting me really excited!",
        // img: PersonA,
        name: "Customer Success Leader",
        title: "Customer Service TechCo",
    },
    {
        header: "TestBox is immeasurably helpful!",
        text:
            "Buying software will be way better now I can play around in a sandbox environment to see what is intuitive, easy to use, and how each solution is differentiated.",
        // img: PersonB,
        name: "Head of Product Success",
        title: "Health Tech",
    },
    {
        header: "This totally redefines a huge problem",
        text:
            "The ability to get our team using the software during the selection process will make them feel included, give them a voice, and reduce frustration or resentment of a new system being forced onto them",
        // img: PersonA,
        name: "CFO",
        title: "Manufacturing",
    },
    {
        header: "Awesome to test products side-by-side",
        text:
            "TestBox is absolutely a need, particularly in less mature companies who want the education and support to see what is really possible",
        name: "Head of Customer Support",
        title: "FinTech",
    },
    {
        header: "We need a sandbox like TestBox",
        text:
            "Right now it takes so long to get a demo of a product that is an empty shell, get data in there and then map it out, and then the trial is over, and you want to do that for lots of vendors",
        name: "Head of Customer Ops",
        title: "Food Tech",
    },
];

const CarouselOverride = createGlobalStyle`
    .BrainhubCarousel__track :first-child {
        margin-left: 0 !important;
    }
`;

const BlockOverride = styled(Block)`
    && {
        padding-right: 0px;
        padding-bottom: 120px;
        margin-right: 0;

        ${Header} {
            align-items: flex-start;
        }
    }
`;

const HeaderButtons = styled.div`
    display: flex;
    flex-direction: row;

    @media (min-width: ${breakpoints.small}) {
        margin-right: 120px;
        gap: 32px;
    }
    @media (max-width: ${breakpoints.small}) {
        margin-right: 20px;
    }
`;

export default function Testimonials() {
    const [current, setCurrent] = useState(0);

    return (
        <BlockOverride
            header="What people have to say about us..."
            actions={
                <HeaderButtons>
                    <ArrowLeft
                        active={current > 0}
                        onClick={() =>
                            current > 0 ? setCurrent(current - 1) : null
                        }
                    />
                    <ArrowRight
                        active={current < testimonials.length - 1}
                        onClick={() =>
                            current < testimonials.length - 1
                                ? setCurrent(current + 1)
                                : null
                        }
                    />
                </HeaderButtons>
            }
        >
            <CarouselOverride />
            <Carousel
                value={current}
                onChange={setCurrent}
                itemWidth={494}
                offset={60}
                breakpoints={{ 840: { itemWidth: null, offset: null } }}
            >
                {testimonials.map((t, idx) => (
                    <Testimonial {...t} current={current === idx} />
                ))}
            </Carousel>
        </BlockOverride>
    );
}
