import React from "react";

import HighFive from "../../assets/high-five-illustration.png";
import Puzzle from "../../assets/puzzle-illustration.png";
import Task from "../../assets/task-illustration.png";
import Pedals from "../../assets/pedals-illustration.png";

import HighFive2X from "../../assets/high-five-illustration@2x.png";
import Puzzle2X from "../../assets/puzzle-illustration@2x.png";
import Task2X from "../../assets/task-illustration@2x.png";
import Pedals2X from "../../assets/pedals-illustration@2x.png";

import Screenshot from "../../assets/screenshot.png";
import Screenshot2X from "../../assets/screenshot@2x.png";

import {
    PointWrapper,
    Img,
    ImgWrapper,
    PointText,
    PointSubtext,
    Wrapper,
    PointHeader,
    Block
} from "./Evaluation.styles";

function Point({ imgSrc, srcSet, heading, text }) {
    return (
        <PointWrapper>
            <ImgWrapper>
                <Img src={imgSrc} srcSet={`${srcSet} 2x`} />
            </ImgWrapper>
            <PointText>
                <PointHeader>{heading}</PointHeader>
                <PointSubtext>{text}</PointSubtext>
            </PointText>
        </PointWrapper>
    );
}

export default function Evaluation() {
    return (
        <Block header="TestBox gives you the hands-on experience you've always wanted...">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'block', textAlign: 'center', marginBottom: 60, }}>
                    <img src={Screenshot} srcSet={`${Screenshot2X} 2x`} style={{ width: '75%' }} />
                </div>
            <Wrapper>
                <Point
                    imgSrc={Task}
                    srcSet={Task2X}
                    heading="Test different options"
                    text="Play with your top choices side-by-side in a fully interactive sandbox"
                />
                <Point
                    imgSrc={Puzzle}
                    srcSet={Puzzle2X}
                    heading="Configured just for you"
                    text="Your TestBox is customized with data and use cases that matter most to you"
                />
                <Point
                    imgSrc={HighFive}
                    srcSet={HighFive2X}
                    heading="Easy team decisions"
                    text="Invite your team to test each solution and easily review your collective feedback — no more spreadsheets!"
                />
                <Point
                    imgSrc={Pedals}
                    srcSet={Pedals2X}
                    heading="Faster implementation"
                    text="Take your TestBox setup with you once you've decided on the best solution"
                />
            </Wrapper>
            </div>
        </Block>
    );
}
