import React from "react";
import styled from "styled-components";
import * as colors from "../../styles/colors";
import breakpoints from "../design-system/Breakpoints";

const Wrapper = styled.div`
    height: 52px;
    cursor: pointer;

    @media (max-height: ${breakpoints.small}) {
        && :first-child {
            margin-right: 8px;
        }
    }
`;

export default function ArrowLeft({ active, ...props }) {
    const color = active ? colors.DARK_GREEN : colors.DISABLED;
    return (
        <Wrapper {...props}>
            <svg
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="51"
                    height="51"
                    rx="3.5"
                    fill="white"
                    stroke={color}
                />
                <path
                    d="M27.2891 33.9219C27.6797 33.5312 27.6406 32.9453 27.2891 32.5547L22.5625 28.0625H33.8125C34.3203 28.0625 34.75 27.6719 34.75 27.125V25.875C34.75 25.3672 34.3203 24.9375 33.8125 24.9375H22.5625L27.2891 20.4844C27.6406 20.0938 27.6797 19.5078 27.2891 19.1172L26.4297 18.2578C26.0781 17.9062 25.4531 17.9062 25.1016 18.2578L17.5234 25.875C17.1328 26.2266 17.1328 26.8125 17.5234 27.1641L25.1016 34.7812C25.4531 35.1328 26.0391 35.1328 26.4297 34.7812L27.2891 33.9219Z"
                    fill={color}
                />
            </svg>
        </Wrapper>
    );
}
