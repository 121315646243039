import React from "react";
import * as colors from "../../styles/colors";

export default function Quote() {
    return (
        <svg
            width="44"
            height="36"
            viewBox="0 0 44 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.70968 36C0.903225 30.6667 0 25.3778 0 20.1333C0 14.0889 1.54839 9.24444 4.64516 5.60001C7.74194 1.86667 12.2581 0 18.1935 0V8.4C13.6344 8.4 11.3548 11.4222 11.3548 17.4667V19.4667H19.6129V36H2.70968ZM27.0968 36C25.3763 30.5778 24.5161 25.2889 24.5161 20.1333C24.5161 14.0889 26.0215 9.24444 29.0323 5.60001C32.129 1.86667 36.6452 0 42.5806 0V8.4C38.1075 8.4 35.871 11.4222 35.871 17.4667V19.4667H44V36H27.0968Z"
                fill={colors.SUBTLE_RED}
            />
        </svg>
    );
}
