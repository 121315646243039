import React from "react";
import styled from "styled-components";

import * as colors from "../../styles/colors";
import Quote from "./Quote";
import breakpoints from "../design-system/Breakpoints";
import { fontFamilies } from "../../styles/fonts";

const Container = styled.div`
    background-color: ${({ current }) => (current ? colors.NEW_TEAL : "white")};
    border: 1.2px solid ${colors.LIGHT_TEAL};
    border-radius: 8px;
    box-shadow: 24px 16px 52px rgba(178, 201, 193, 0.2);
    display: flex;
    flex-direction: column;
    color: ${({ current }) => (current ? "white" : colors.DARK_GREEN)};

    @media (min-width: ${breakpoints.small}) {
        padding-top: 52px;
        padding-left: 60px;
        padding-bottom: 52px;
        padding-right: 60px;
        width: 494px;
        height: 398px;
    }
    @media (max-width: ${breakpoints.small}) {
        width: 80%;
        padding-right: 20px;
        padding-top: 40px;
        padding-left: 20px;
        padding-bottom: 40px;
    }
`;

const Header = styled.h4`
    font-family: ${fontFamilies.header};
    font-weight: 800;
    font-size: 20px;
    line-height: 128%;
    margin-top: 32px;
`;

const Text = styled.div`
    font-family: Roboto, Arial, sans-serif;
    font-size: 1.125rem;
    line-height: 25px;
    margin-bottom: 80px;
    color: ${({ current }) => current ? '#fff' : '#4F5C5C'};
`;

const Author = styled.div`
    display: flex;
    flex-direction: row;
`;

const AuthorText = styled.div`
    display: flex;
    flex-direction: column;
`;

const AuthorName = styled.div`
    font-family: ${fontFamilies.header};
    font-size: 1.125rem;
    font-weight: 800;
    line-height: 128%;
    margin-bottom: 8px;
`;

const AuthorTitle = styled.div`
    font-family: Roboto, Arial, sans-serif;
    font-size: 0.875rem;
    color: ${({ current }) => current ? '#fff' : '#4F5C5C'};
`;

// const Person = styled.img`
//     width: 52px;
//     height: 52px;
//     border-radius: 50px;
//     margin-right: 8px;
// `;

export default function Testimonial({
    header,
    text,
    img,
    name,
    title,
    current,
}) {
    return (
        <Container current={current}>
            <Quote />
            <Header>{header}</Header>
            <Text current={current}>{text}</Text>
            <Author>
                {/* <Person src={img} /> */}
                <AuthorText>
                    <AuthorName>{name}</AuthorName>
                    <AuthorTitle current={current}>{title}</AuthorTitle>
                </AuthorText>
            </Author>
        </Container>
    );
}
